import * as React from "react"
import emailjs from "emailjs-com"
import Form from "react-bootstrap/Form"
import Button from "react-bootstrap/Button"
import * as terminStyles from "./termin.module.css"
import Select from "@material-ui/core/Select"
import NativeSelect from "@material-ui/core/NativeSelect"

export default function Termin() {
  return (
    <div>
      <div className={terminStyles.beratungsFormWrapper}>
        <h2 className={terminStyles.heading}>
          Machen Sie jetzt einen kostenlosen Beratungstermin aus, um ein
          ausführliches und festes Angebot für Ihre Wunschwebsite zu erhalten.
        </h2>
        <TerminForm />
      </div>
      <div className={terminStyles.beratungsFormWrapper}>
      <h2 className={terminStyles.heading}>...Sie können uns auch einfach per Telefon oder E-Mail kontaktieren, um einen Beratungsgespräch auszumachen.</h2>
      <div className={terminStyles.contactInfo}>
        <div>
          <h3 className="bold center">TEL: +49 179 5008401</h3>
        </div>
        <div>
          <h3 className="bold center">E-MAIL: NIKOLAI@ALTERGOTTSTUDIOS.COM</h3>
        </div>
        </div>
      </div>
    </div>
  )
}

class TerminForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      email: "",
      from_name: "",
      date_time: "",
      meeting_type: "",
      telephone: "",
      error: false,
      success: false,
    }

    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.onError = this.onError.bind(this)
  }

  handleInputChange(event) {
    const target = event.target
    const value = target.type === "checkbox" ? target.checked : target.value
    const name = target.name

    this.setState({
      [name]: value,
    })

    console.log("input change: " + name + ", " + value)
  }

  handleSubmit(event) {
    const form = event.currentTarget
    if (form.checkValidity() === false) {
      event.preventDefault()
      event.stopPropagation()
    }

    event.preventDefault()

    const templateId = "template_6psgj3e"

    this.sendFeedback(templateId, {
      from_name: this.state.from_name,
      email: this.state.email,
      date_time: this.state.date_time,
      meeting_type: this.state.meeting_type,
      telephone: this.state.telephone,
    })
  }

  sendFeedback(templateId, variables) {
    emailjs.init("user_OwubmPmDYsUpFhOWNq0TQ")
    emailjs
      .send("service_lj2ajxn", templateId, variables)
      .then(res => {
        //alert('Your message is sent!');
        this.setState(state => {
          // Important: read `state` instead of `this.state` when updating.
          return { success: true }
        })
        return
      })
      // Handle errors here however you like, or use a React error boundary
      .catch(this.onError)
  }

  onError() {
    this.setState(state => {
      // Important: read `state` instead of `this.state` when updating.
      return { error: true }
    })
  }

  render() {
    if (this.state.error) {
      return (
        <div>
          <div>
            <h3>
              Es ist ein Fehler aufgetreten, bitte versuchen Sie es nochmal.
            </h3>
          </div>
        </div>
      )
    }

    if (this.state.success) {
      return (
        <div>
          <div>
            <h3>Vielen Dank für Ihre Nachricht!</h3>
          </div>
        </div>
      )
    }

    return (
      <div>
        <Form
          onSubmit={this.handleSubmit}
          className={terminStyles.beratungsForm}
        >
          <Form.Group controlId="exampleForm.ControlInput1">
          <Form.Label>Wie heißen Sie?</Form.Label>
            <Form.Control
              className={terminStyles.inputText}
              name="from_name"
              required
              size="lg"
              type="text"
              placeholder="Ihr Name"
              onChange={this.handleInputChange}
            />
          </Form.Group>

          <Form.Group controlId="exampleForm.ControlInput1">
          <Form.Label>Ihre E-Mail Addresse</Form.Label>
            <Form.Control
              className={terminStyles.inputText}
              name="email"
              required
              size="lg"
              type="email"
              placeholder="Ihre Email"
              onChange={this.handleInputChange}
            />
          </Form.Group>

          <Form.Group controlId="exampleForm.ControlInput1">
          <Form.Label>Gewünschter Termin</Form.Label>
            <Form.Control
              className={terminStyles.inputText}
              name="date_time"
              required
              size="lg"
              type="datetime-local"
              placeholder="Gewünschter Termin"
              onChange={this.handleInputChange}
            />
          </Form.Group>

          <Form.Group controlId="exampleForm.ControlInput1">
          <Form.Label>Wie sollte das Gespräch stattfinden?</Form.Label>
            <select
              className={terminStyles.select}
              name="meeting_type"
              onChange={this.handleInputChange}
              defaultValue="Choose..."
            >
              <option>Wie sollten wir das Beratungsgespräch halten?</option>
              <option>Per Telefongespräch</option>
              <option>Per Videokonferenz (Zoom)</option>
              <option>Über ein persönliches Treffen</option>
            </select>
          </Form.Group>

          <Form.Group controlId="exampleForm.ControlInput1">
          <Form.Label>Unter welcher Nummer können wir Sie kontaktieren?</Form.Label>
            <Form.Control
              className={terminStyles.inputText}
              name="telephone"
              required
              size="lg"
              type="tel"
              placeholder="Ihre Telefonnummer"
              onChange={this.handleInputChange}
            />
          </Form.Group>

          <Form.Group className={terminStyles.submitButton}>
            <Button
              variant="outline-dark"
              size="lg"
              type="submit"
              className="actionButton black"
            >
              Beratungstermin Ausmachen
            </Button>
          </Form.Group>
        </Form>
      </div>
    )
  }
}
