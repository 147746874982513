import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Termin from "../components/termin"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"


const TerminPage = () => (
  <div>
  <Seo title="Beratungstermin Ausmachen - Altergot Studios" lang="de" description="Wir entwickeln effektive, marken-treue und erschwingliche Websites. Jetzt ein kostenloses Angebot anfordern!" />
  <Layout>
  <Termin />
  </Layout>
    
  </div>
)

export default TerminPage